<template>
  <div>
    <customer-center-list-cart :customer_id="company_id" />
  </div>
</template>

<script>
import CustomerCenterListCart from "@/views/custom_app/components/plants/CustomerCenterListCart.vue"

export default {
  components: {
    CustomerCenterListCart,
  },
  data() {
    return {
      company_id: '',
    }
  },
  created() {
    this.company_id = localStorage.getItem('userData').company_id
  },
}
</script>

<style lang="scss">
</style>
